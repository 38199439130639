import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import cn from 'classnames'

import DfpAdSlot from 'components/DfpAdSlot'
import MenuToolbar from 'components/MenuToolbar'
import theme from '../pages/blog.module.scss'
import themeUtils from 'styles/utils.module.scss'
import { BLOGLIST_BOTTOM_AD1, SOFTWARE_DEVELOPER } from 'config/dfpAds'
import { BrowserView } from 'react-device-detect'
import BlogInfo from './components/BlogInfo'
import RecentPost from 'components/ui/RecentPost'


export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { markdownRemark: post } = data // data.markdownRemark holds our post data
  //const { isCourse = true } = post
  if (!post) {
    return (
      <div>
        <h1>No Post Data Found</h1>
      </div>
    )
  }
  console.log(data)
  const isCourse = true
  return (
    <>
      <Helmet title={`${post.frontmatter.topic || 'The Startup Lab'} - ${post.frontmatter.title}`} />
      <MenuToolbar />
      <div className={theme.Blog}>
        <div className={theme.Blog_Content}>
          <div className={theme.Blog_Container}>
            <h1>{post.frontmatter.title}</h1>
            <BlogInfo
              author={post.frontmatter.author}
              date={post.frontmatter.date}
            />
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            {isCourse && (
              <div>
                <p>Previous Lesson</p>
                <p>Next Lesson</p>
              </div>
            )}
          </div>
        </div>
        <BrowserView viewClassName={cn(theme.Blog_GutterRight, themeUtils.hideXS)}>
          <RecentPost />
          <DfpAdSlot adKey={SOFTWARE_DEVELOPER} />
          <DfpAdSlot className={theme.Blog_Ad_sticky} adKey={BLOGLIST_BOTTOM_AD1} />
        </BrowserView>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query VideoPostByPath($title: String!, $pageNum: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } page: { eq: $pageNum } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        page
        title
        author
      }
    }
  }
`