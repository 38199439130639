import React from 'react'
import theme from './BlogInfo.module.scss'

const BlogInfo = ({ author, date, updated }) => (
 <div className={theme.BlogInfo_Container}>
  <div className={theme.BlogInfo_Item}>
   <h4>Author</h4>
   <label>{author}</label>
  </div>
  <div className={theme.BlogInfo_Item}>
   <h4>Date Published</h4>
   <label>{date}</label>
  </div>
  {updated && (
   <div className={theme.BlogInfo_Item}>
    <h4>
     Updated
    </h4>
    <label>{updated}</label>
   </div>
  )}
 </div>
)

export default BlogInfo
